/* --- RestaurantPage.css --- */

/* Reset */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  /* Page Container */
  .restaurant-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Header Section */
  .restaurant-header {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    background-color: #fff;
  }
  .header-content {
    max-width: 1200px;
    width: 100%;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: center;
  }
  .header-image img {
    width: 400px;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
  }
  .header-details {
    flex: 1;
    min-width: 280px;
  }
  .header-details h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  .header-details .location {
    color: #555;
  }
  .header-buttons {
    margin-top: 1rem;
  }
  .view-menu-button {
    background-color: #e63946;
    color: #fff;
    padding: 0.75rem 1.25rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  .view-menu-button:hover {
    background-color: #d62839;
  }
  
  /* Sticky/Floating Cart Button */
  .cart-float-button {
    position: fixed;
    top: 120px; /* adjust as needed */
    right: 30px;
    z-index: 999;
  }
  .cart-button {
    background-color: #e63946;
    color: #fff;
    padding: 0.75rem 1.25rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
    position: relative; /* for the badge */
  }
  .cart-button:hover {
    background-color: #d62839;
  }
  .cart-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #fff;
    color: #e63946;
    width: 20px;
    height: 20px;
    font-size: 0.75rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  
  /* Main Content: center + limit width */
  .restaurant-content {
    flex: 1;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  
  /* Category Bar */
  .category-bar {
    display: flex;
    margin-bottom: 2rem;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
  }
  .category-bar::-webkit-scrollbar {
    display: none;
  }
  .category-item {
    margin: 0 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: 2px solid #e63946;
    background-color: #fff;
    color: #e63946;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    flex-shrink: 0;
  }
  .category-item.active,
  .category-item:hover {
    background-color: #e63946;
    color: #fff;
  }
  
  /* Food Items Section */
  .food-items-section {
    width: 100%;
  }
  .food-items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 1.5rem;
  }
  .food-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .food-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 20px rgba(0,0,0,0.15);
  }
  .food-card-content h3 {
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
  }
  .food-card-content .price {
    color: #d62839;
    font-weight: 600;
    margin-top: 0.5rem;
  }
  .quantity-controls {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .quantity-controls button {
    background-color: #fff;
    border: 1px solid #e63946;
    color: #e63946;
    padding: 0.5rem;
    border-radius: 50%;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  .quantity-controls button:hover {
    background-color: #e63946;
    color: #fff;
  }
  
  /* CART MODAL (Backdrop + Slide-in) */
  .cart-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    cursor: pointer;
  }
  .cart-modal {
    position: fixed;
    top: 0;
    right: 0;
    width: 350px;
    height: 100vh;
    background-color: #fff;
    box-shadow: -2px 0 6px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    z-index: 9999;
  }
  .cart-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .cart-modal-header h2 {
    font-size: 1.25rem;
    margin: 0;
  }
  .close-cart-button {
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    line-height: 1;
    padding: 0;
    margin: 0;
  }
  .empty-cart-msg {
    font-size: 1rem;
    margin-top: 1rem;
    color: #555;
  }
  .cart-items {
    list-style: none;
    flex: 1;
    overflow-y: auto;
    margin-bottom: 1rem;
  }
  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
  }
  .cart-item .item-name {
    font-weight: 500;
    width: 40%;
  }
  .cart-item-controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .icon-button {
    background-color: #fff;
    border: 1px solid #e63946;
    color: #e63946;
    padding: 0.4rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-button:hover {
    background-color: #e63946;
    color: #fff;
  }
  .delete-button {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333;
    border-radius: 4px;
    padding: 0.4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .delete-button:hover {
    background-color: #ccc;
  }
  
  /* Promo code area */
  .promo-code-area {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  .promo-code-area input {
    flex: 1;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  .apply-promo-button {
    background-color: #2a9d8f;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  .apply-promo-button:hover {
    background-color: #21867a;
  }
  .promo-message {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: #d62839; /* or green if success, etc. */
  }
  
  /* Cart breakdown totals */
  .cart-breakdown {
    margin: 1rem 0;
    border-top: 1px solid #ddd;
    padding-top: 1rem;
  }
  .cart-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }
  .cart-row.total {
    font-weight: 600;
    font-size: 1rem;
  }
  .checkout-button {
    width: 100%;
    background-color: #e63946;
    color: #fff;
    padding: 0.75rem 1.25rem;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-top: 0.5rem;
  }
  .checkout-button:hover {
    background-color: #d62839;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .header-content {
      flex-direction: column;
      align-items: flex-start;
    }
    .header-image img {
      width: 100%;
      max-width: 300px;
      margin-bottom: 1rem;
    }
    /* Align categories to start on mobile. */
    .category-bar {
      justify-content: flex-start;
    }
    /* Make the cart modal full width on small screens */
    .cart-modal {
      width: 100%;
      max-width: 400px;
    }
  }
  