.calendar-container {
  animation: fadeIn 0.5s ease-out forwards;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  flex-direction: column;
}

.rbc-toolbar {
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px; 
  width: 100%;
  box-sizing: border-box;
}

.rbc-toolbar button {
  background-color: #393939;
  color: white;
  border: none;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.rbc-toolbar button:hover {
  background-color: black;
  color: white;
}

.rbc-event {
  border-radius: 4px !important;
  padding: 5px;
  font-size: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.custom-event {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-time {
  font-size: 10px;
  margin-top: 2px;
  color: #f0f0f0;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 1000;
}

.ReactModal__Content {
  position: fixed;
  inset: 50% auto auto 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
  overflow: auto;
  outline: none;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  z-index: 1100;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-content p {
  margin: 10px 0;
}

.mobile-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background-color: #f5f5f5;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  color: #333;
  font-size: 18px;
  padding: 20px;
  box-sizing: border-box;
}

/* Responsive Design */
@media (max-width: 768px) {
  .rbc-calandar {
    height: 60vh;
  }

  .calendar-container {
    height: auto;
    width: 100%;
    padding: 5px;
  }

  .rbc-toolbar {
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 5px;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
  }

  .rbc-toolbar-label {
    font-size: 14px;
    text-align: center; 
    margin-bottom: 5px;
    width: 100%;
  }

  .rbc-toolbar button {
    flex: 1; 
    width: 100%; 
    max-width: 250px;
    font-size: 12px;
    padding: 6px;
    text-align: center;
  }

  .rbc-btn-group {
    display: flex;
    justify-content: center; 
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
  }

  .rbc-event {
    font-size: 10px;
  }
  .rbc-month-view {
    height: 50vh;
    flex: none;
  }
}

@media (max-width: 480px) {
  .rbc-toolbar {
    flex-direction: column;
    align-items: center;
  }

  .rbc-toolbar button {
    margin: 5px 0;
    width: 100%; 
  }

  .rbc-event {
    font-size: 9px;
  }

  .custom-event {
    align-items: center;
  }
}
