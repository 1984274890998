.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.menu-table-container {
  margin-top: 10px;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 6px;
}

/* The table itself */
.menu-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 500px; /* optional min width */
}

.menu-table th,
.menu-table td {
  border: 1px solid #eee;
  padding: 8px;
  text-align: left;
}

.menu-table th {
  background-color: black;
}

.menu-table input[type="text"],
.menu-table textarea {
  width: 95%;
  border: 1px solid #ccc;
  padding: 4px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.menu-table textarea {
  resize: vertical;
}

/* Make sure the table doesn't overflow horizontally */
.menu-table-container table {
  width: 100%;
  overflow-x: auto;
}

.modal-content {
  background: #fff;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}

.modal-content h2 {
  margin-top: 0;
}

.instruction {
  font-size: 0.9rem;
  color: #444;
  margin-bottom: 10px;
}

.menu-items {
  max-height: 300px;
  overflow-y: auto;
  margin: 10px 0;
  border: 1px solid #ddd;
  padding: 10px;
}

.menu-item {
  border-bottom: 1px solid #eee;
  padding: 5px 0;
}

.restaurant-select {
  margin: 10px 0;
}

.error {
  color: red;
  margin: 10px 0;
}

.close-modal {
  background: #e63946;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
}
