.add-kitchen {
  animation: fadeIn 0.5s ease-out forwards;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.add-kitchen h1 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.2;
}

.progress-indicator {
  margin-bottom: 30px;
}

.progress-indicator ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

.progress-indicator li {
  font-size: 14px;
  color: #ccc;
  text-transform: uppercase;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.progress-indicator li:hover {
  color: #333;
  font-weight: bolder;
  font-size: 18px;
}

.progress-indicator li.active {
  color: #e53935;
  font-weight: bolder;
  font-size: 18px;
}

.progress-indicator li.active::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #e53935;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 14px;
  font-weight: bold;
  color: black;
  margin-bottom: 8px;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group select,
.form-group input[type="text"]::placeholder {
  color: #888;
}

.form-group textarea {
  resize: vertical;
}

.form-group select {
  appearance: none;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  width: 100%;
  cursor: pointer;
  transition: border-color 0.3s;
}

.form-group select option {
  color: #333;
}

@media screen and (max-width: 768px) {
  .icon-view {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 10px 0;
  }

  .icon-view li {
    text-align: center;
    flex: 1;
    cursor: pointer;
    font-size: 24px;
    color: #333;
    transition: color 0.3s, transform 0.3s;
  }

  .icon-view li.active {
    color: #e53935;
  }

  .icon-view li p {
    font-size: 12px;
    margin-top: 5px;
    color: #666;
  }

  .add-kitchen h1 {
    font-size: 22px;
    margin-bottom: 15px;
  }

  .progress-indicator ul {
    align-items: center;
    gap: 10px;
  }

  .progress-indicator li {
    font-size: 16px;
    width: 100%;
  }

  .progress-indicator li.active::before {
    top: -10px;
    left: 50%;
  }
}

@media screen and (max-width: 480px) {
  .add-kitchen h1 {
    font-size: 18px;
  }

  .progress-indicator ul {
    gap: 5px;
  }

  .progress-indicator li {
    font-size: 14px;
  }

  .progress-indicator li.active {
    font-size: 16px;
  }
}
