.login-page {
  animation: fadeIn 0.5s ease-out forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
  overflow: hidden;
}

/* Login Container */
.login-page__container {
  background-color: #ffffff;
  padding: 50px 40px;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 450px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.login-page__logo-link {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.login-page__logo-image {
  width: 100%;
  max-width: 150px;
  height: auto;
}

.login-page__title {
  font-size: 2rem;
  color: #333333;
  margin-bottom: 25px;
}

.login-page__error-message,
.login-page__success-message {
  width: 100%;
  text-align: center;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-weight: bold;
}

.login-page__error-message {
  color: #e63946;
  background-color: #ffe6e6;
}

.login-page__success-message {
  color: #2a9d8f;
  background-color: #e6f4f1;
}

.login-page__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.login-page__form-group {
  width: 100%;
  margin-bottom: 20px;
}

.password-group {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 55%;
  right: 12px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2rem;
  color: #888888;
  transition: color 0.3s ease;
}

.password-toggle-icon:hover {
  color: #333333;
}

.login-page__input {
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.login-page__input:focus {
  border-color: #2a9d8f;
  box-shadow: 0 0 5px rgba(42, 157, 143, 0.3);
  outline: none;
}

.login-page__login-btn {
  width: 100%;
  padding: 14px 16px;
  background-color: #2a9d8f;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.login-page__login-btn:hover {
  background-color: #21867a;
  transform: translateY(-2px);
}

.login-page__login-btn:active {
  transform: translateY(0);
}

.login-page__signup-link {
  margin-top: 25px;
  font-size: 1rem;
  color: #333333;
}

.login-page__signup-text {
  color: #2a9d8f;
  cursor: pointer;
  transition: color 0.3s ease;
}

.login-page__signup-text:hover {
  text-decoration: underline;
  color: #21867a;
}

@media (max-width: 480px) {
  .login-page__container {
    padding: 30px 25px;
    max-width: 90%;
  }

  .login-page__logo-image {
    max-width: 120px;
  }

  .login-page__title {
    font-size: 1.6rem;
  }

  .login-page__input {
    padding: 12px 14px;
    font-size: 0.95rem;
  }

  .login-page__login-btn {
    padding: 12px 14px;
    font-size: 0.95rem;
  }
}
