.featured-kitchens {
  padding: 30px 20px;
  text-align: center;
  background-color: #fff;
}

.featured-kitchens h2 {
  font-size: 2.4rem;
  color: #2c3e50;
  font-weight: 700;
}

.kitchens-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.featured-kitchen-card {
  position: relative;
  max-width: 350px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
  text-align: left;
}

.featured-kitchen-card:hover {
  transform: translateY(-10px);
}

.kitchen-image-wrapper {
  position: relative;
  height: 300px;
  width: 500px;
  overflow: hidden;
}

.kitchen-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.featured-kitchen-card:hover .kitchen-image-wrapper img {
  transform: scale(1.05);
}

.kitchen-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 20px;
  text-align: left;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.featured-kitchen-card:hover .kitchen-overlay {
  transform: translateY(0);
}

.kitchen-overlay h3 {
  font-size: 1.8rem;
  margin-bottom: 5px;
  color: #fff;
}

.kitchen-overlay p {
  font-size: 1.2rem;
  margin: 5px 0;
}

.kitchen-overlay .price {
  font-size: 1.5rem;
  font-weight: 700;
  color: #e63946;
}

@media (max-width: 768px) {
  .kitchens-container {
    flex-direction: column;
    align-items: center;
  }

  .featured-kitchen-card {
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .featured-kitchens h2 {
    font-size: 2.2rem;
  }

  .kitchen-overlay h3 {
    font-size: 1.5rem;
  }

  .kitchen-overlay p {
    font-size: 1rem;
  }

  .kitchen-overlay .price {
    font-size: 1.2rem;
  }
}
