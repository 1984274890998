.add-food-item-container {
  animation: fadeIn 0.5s ease-out forwards;
  background-color: white;
  padding: 40px;
  max-width: 900px;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-food-item-container h2.form-title {
  color: #333;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.add-food-item-container .form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.add-food-item-container .form-group {
  flex: 1;
}

.add-food-item-container button.submit-btn {
  padding: 12px 20px;
  background-color: #393939;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-food-item-container button.submit-btn:hover {
  background-color: #555;
  transform: scale(1.02);
}

.upload-menu {
  display: flex;
  justify-content: center;
}

.upload-menu-btn {
  text-decoration: none;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  color: white;
  border-radius: 5px;
  background-color: #393939;
}

.upload-menu-btn:hover {
  background-color: #000;
  transform: scale(1.05);
}

.upload-menu-btn:active {
  transform: scale(0.95);
}

.upload-menu-btn:focus {
  outline: 2px solid #007bff; /* or your preferred focus style */
  outline-offset: 2px;
}
/* Responsive Styles */
@media screen and (max-width: 768px) {
  .add-food-item-container {
    padding: 20px;
  }

  .add-food-item-container h2.form-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .add-food-item-container button.submit-btn {
    font-size: 0.9rem;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 480px) {
  .add-food-item-container {
    padding: 15px;
  }

  .add-food-item-container h2.form-title {
    font-size: 1.2rem;
  }

  .add-food-item-container button.submit-btn {
    font-size: 0.8rem;
    padding: 8px 12px;
  }
}
