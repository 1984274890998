.settings-container {
  animation: fadeIn 0.5s ease-out forwards;
  padding: 20px;
  min-height: 100vh;
  font-size: 18px;
  color: #555;
  background-color: #f9f9f9;
}

.settings-container h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tabs button {
  background-color: #e0e0e0;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px 4px 0 0;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
}

.tabs button:hover {
  background-color: #d5d5d5;
}

.tabs button.active {
  background-color: white;
  border-bottom: 2px solid white;
  font-weight: bold;
  margin: 0px;
}

.tab-content {
  animation: fadeIn 0.5s ease-out forwards;
  background-color: white;
  padding: 25px;
  border-radius: 0 5px 5px 5px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
}

.documents-tab .verification-section,
.documents-tab .upload-documents {
  margin-bottom: 30px;
}

.documents-tab h2 {
  color: #333;
  margin-bottom: 15px;
  font-size: 1.2em;
}

.verification-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verification-section h2 {
  margin-bottom: 10px;
}

.verification-section .status-indicator {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.status-icon {
  font-size: 1.2em;
  margin-right: 10px;
}

.status-icon.success {
  color: #28a745;
}

.status-icon.error {
  color: #dc3545;
}

.status-icon.loading {
  color: #ffc107;
}

.spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.upload-documents .document-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 5px;
}

.upload-documents .document-item label {
  flex: 1 1 100px;
  font-weight: bold;
  min-width: 200px;
}

.upload-documents .document-item input[type="file"] {
  flex: 2 1 150px;
  padding: 5px;
}

.upload-documents .document-item button {
  padding: 8px 16px;
  background-color: #393939;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.upload-documents .document-item button:hover:not(:disabled) {
  background-color: black;
  transform: translateY(-2px);
}

.upload-documents .document-item button:disabled {
  background-color: #777777;
  cursor: not-allowed;
}

.status-container {
  display: flex;
  align-items: center;
}

.status-text {
  font-size: 14px;
  font-weight: 500;
}

.status-text.uploaded {
  color: #28a745;
}

.status-text.uploading {
  color: #ffc107;
}

.status-text.upload-failed {
  color: #dc3545;
}

.status-text.ready-to-upload {
  color: #17a2b8;
}

.upload-documents .document-item a {
  margin-left: 10px;
  color: #393939;
  text-decoration: none;
  font-size: 14px;
}

.upload-documents .document-item a:hover {
  text-decoration: underline;
}

.account-settings-tab,
.personal-info-tab {
  text-align: center;
  padding: 50px 0;
  color: #777;
}

.verification {
  padding: 10px 20px;
  background-color: #393939;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.verification:hover {
  background-color: black;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .tabs {
    align-items: center;
    width: 100%;
  }

  .documents-tab h2 {
    margin-bottom: 10px;
    font-size: 0.9em;
  }

  .upload-documents .document-item {
    align-items: flex-start;
  }

  .upload-documents .document-item label,
  .upload-documents .document-item span,
  .upload-documents .document-item a {
    margin-left: 0;
    margin-top: 5px;
    font-size: 0.8rem;
  }

  .status-container {
    flex: 1 1 100%;
    margin-top: 5px;
  }

  .upload-documents .document-item button {
    width: 100%;
    justify-content: center;
  }
}
