/* Overlay for the popup */
.newsletter-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup container */
  .newsletter-popup {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 90%; /* Responsive width */
    position: relative;
    box-sizing: border-box;
  }
  
  /* Icon styling */
  .newsletter-popup-icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  /* Message styling */
  .newsletter-popup-message {
    margin: 10px 0;
    font-size: 1.2rem;
    color: #333;
    word-wrap: break-word; /* Handle long messages */
  }
  
  /* Close button styling */
  .newsletter-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #333;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .newsletter-popup {
      padding: 15px;
      font-size: 1rem;
    }
  
    .newsletter-popup-message {
      font-size: 1rem;
    }
  
    .newsletter-popup-icon {
      font-size: 1.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .newsletter-popup {
      padding: 10px;
      font-size: 0.9rem;
    }
  
    .newsletter-popup-message {
      font-size: 0.9rem;
    }
  
    .newsletter-popup-icon {
      font-size: 1.5rem;
    }
  }
  