.restaurant-card-link {
  text-decoration: none;
  color: inherit;
  display: block;
  position: relative;
}

.restaurant-card {
  background-color: #ffffff;
  border-radius: 3px;
  animation: fadeInCard 0.5s ease-in forwards;
}

@keyframes fadeInCard {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.restaurant-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.restaurant-image-container {
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
}

.restaurant-image {
  width: 100%;
  height: 100%;
  border-color: black !important;
  object-fit: cover;
  transition: transform 0.5s ease;
}

/* Slight zoom on hover */
.restaurant-card:hover .restaurant-image {
  transform: scale(1.05);
}

.restaurant-info {
  padding: 5px;
}

.restaurant-name {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0 0 5px;
  color: #333;
}

.restaurant-location,
.restaurant-min-order,
.restaurant-delivery-fee {
  font-size: 0.9rem;
  color: #666;
  margin: 3px 0;
}

.restaurant-card-link:focus .restaurant-card {
  outline: 2px solid #e63946;
  outline-offset: 2px;
}

@media (max-width: 480px) {
  .restaurant-info {
    padding: 10px;
  }

  .restaurant-name {
    font-size: 1rem;
  }

  .restaurant-location,
  .restaurant-min-order,
  .restaurant-delivery-fee {
    font-size: 0.8rem;
  }
}
