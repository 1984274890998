/* Global Page Layout */
.eatz-page {
  background: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Nunito Sans", sans-serif;
  padding-bottom: 20px;
}

/* Content Container */
.eatz-content {
  width: 95%;
  max-width: 1200px;
  margin: 20px auto;
}

/* Hero Section Unique */
.hero-section-unique {
  text-align: center;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}
.hero-section-unique.shrink {
  padding: 10px 20px;
}
.hero-section-unique h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #333;
  transition: font-size 0.3s ease;
}
.hero-section-unique.shrink h1 {
  font-size: 1.8rem;
}
.hero-section-unique p {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #555;
  transition: opacity 0.3s ease;
}

/* Search Bar Styling - Compact and interactive */
.eatz-search-bar {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}

/* Location Search Container with Geo Button inside */
.location-search-container {
  position: relative;
  width: 100%;
  max-width: 350px;
}
.location-search-container input {
  width: 100%;
  box-sizing: border-box;
  padding: 8px 45px 8px 12px; /* extra right padding for the geo button */
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  outline: none;
  transition: transform 0.3s ease, all 0.3s ease;
  flex-shrink: 0;
  height: 36px;
  background-color: #fafafa;
}
.location-search-container.focused input {
  transform: scale(0.95);
}
.location-search-container input:focus {
  border-color: #e63946;
  box-shadow: 0 0 5px rgba(230, 57, 70, 0.3);
}
.location-button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #e63946;
}

/* Common styling for select inputs and DatePicker input */
.eatz-search-bar select,
.date-picker-input,
.eatz-search-bar input {
  box-sizing: border-box;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  height: 36px;
  background-color: #fafafa;
}

/* Date/Search/Filters Container */
.date-search-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
}

/* Filters Toggle Button (Mobile Only) */
.filters-toggle {
  background-color: #e63946;
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.8rem;
  cursor: pointer;
  margin: 0;
  /* Hidden on desktop by default */
}

/* Search Button */
.eatz-search-button {
  background-color: #e63946;
  color: #ffffff;
  font-weight: bold;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  height: 36px;
}
.eatz-search-button:hover {
  background-color: #bf2f39;
  transform: translateY(-2px);
}

/* Advanced Filters Container */
.advanced-filters {
  display: flex;
  gap: 8px;
}
/* Desktop-only advanced filters */
.desktop-only {
  display: flex;
}
/* Mobile-only elements */
.mobile-only {
  display: none;
}

/* Restaurant List Grid */
.restaurant-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  opacity: 0;
  animation: fadeInUp 0.7s forwards;
  animation-delay: 0.1s;
}

/* Force 4 columns on desktop screens */
@media (min-width: 1024px) {
  .restaurant-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Loading Spinner and Message */
.loading {
  text-align: center;
  color: #555;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spinner-res {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top: 4px solid #e63946;
  border-radius: 50%;
  margin-bottom: 10px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* No Results Message */
.no-results {
  text-align: center;
  font-size: 16px;
  color: #666;
  margin-top: 40px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .eatz-search-bar {
    flex-direction: column;
    align-items: stretch;
  }
  .location-search-container {
    max-width: 100%;
  }
  .date-search-container {
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
  }
  /* Hide desktop-only advanced filters on mobile */
  .desktop-only {
    display: none;
  }
  /* Show mobile-only elements on mobile */
  .mobile-only {
    display: inline-block;
    padding: 8px 16px;
    font-size: 16px;
  }
  /* Mobile advanced filters: hidden by default, then displayed when toggled */
  .advanced-filters.mobile-only {
    display: none;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;
  }
  .advanced-filters.mobile-only.show {
    display: flex;
  }
  /* Reduce hero text sizes on mobile */
  .hero-section-unique h1 {
    font-size: 2rem;
    margin-top: auto;
  }
  .hero-section-unique p {
    font-size: 0.9rem;
  }
}
