/* 
  1) Keyframes for a smooth fade-in-up effect
*/
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.restaurant-price {
  animation: fadeIn 0.5s ease-out forwards;
  background-color: white;
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.restaurant-price h2 {
  text-align: center;
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

.restaurant-price label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #555;
}

.restaurant-price input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  box-sizing: border-box;
}

/* Food item form section */
.food-item-form {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.food-item-form h3 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.food-item-form label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #555;
}

.food-item-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
}

/* Food item form button */
.food-item-form button {
  background-color: #28a745;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.food-item-form button:hover {
  background-color: #218838;
  transform: scale(1.02);
}

/* Food items list */
.food-items-list {
  margin-top: 20px;
}

.food-items-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f9fa;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* .food-items-list li div {
  flex: 1;
} */

/* .food-items-list li button {
  margin-left: 10px;
  padding: 8px 15px;
  font-size: 12px;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
} */

.food-items-list li button:hover {
  transform: scale(1.03);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .restaurant-price {
    padding: 15px;
    box-shadow: none;
    border-radius: 0%;
  }

  .food-item-form {
    margin-top: 15px;
  }

  .food-items-list li {
    flex-direction: column;
    align-items: flex-start;
  }

  .food-items-list li button {
    margin-top: 10px;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .restaurant-price h2 {
    font-size: 20px;
  }

  .food-item-form h3 {
    font-size: 18px;
  }
}
