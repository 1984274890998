.kitchen-images {
  animation: fadeIn 0.5s ease-out forwards;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: 40px auto;
}

.kitchen-images h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
  text-align: center;
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin-bottom: 30px;
}

.image-preview {
  position: relative;
  width: 150px;
  height: 150px;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.remove-image-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #393939;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px 8px;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-image-btn:hover {
  background-color: black;
}

.image-entries {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.image-entry {
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.field-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex: 1;
}

.images-form-group {
  flex: 1 1 250px;
  display: flex;
  flex-direction: column;
}

.images-form-group label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px;
  color: #555;
}

.images-form-group input,
.images-form-group select {
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-image-btn {
  background-color: #393939;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: block;
  margin: 0 auto 30px auto;
  transition: background-color 0.3s ease;
}

.add-image-btn:hover {
  background-color: black;
}

.loading-indicator {
  margin-top: 20px;
  color: #666;
  font-size: 16px;
  text-align: center;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .kitchen-images {
    padding: 0%;
    border-radius: 0%;
    box-shadow: none;
  }

  .field-group {
    flex-direction: column;
  }

  .add-image-btn {
    width: 100%;
  }

  .image-preview-container {
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }

  .image-preview {
    width: 120px;
    height: 120px;
  }

  .image-entry {
    flex-direction: column;
    box-shadow: none;
  }

  .images-form-group {
    flex: 1 1 100%;
  }
}

@media screen and (max-width: 480px) {
  .kitchen-images h2 {
    font-size: 22px;
  }

  .image-preview-container {
    flex-direction: column;
    gap: 8px;
  }

  .image-preview {
    width: 100px;
    height: 100px;
  }

  .add-image-btn {
    padding: 10px 16px;
    font-size: 14px;
    margin-bottom: auto;
    width: auto;
  }
}
