.seeker-form {
  animation: fadeIn 0.5s ease-out forwards;
  background-color: white;
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.seeker-form .form-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.seeker-form .form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.seeker-form .form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.seeker-form .form-group label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.seeker-form .form-group input,
.seeker-form .form-group textarea,
.seeker-form .form-group select {
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.seeker-form .form-group textarea {
  resize: vertical;
}

.seeker-form .form-group select {
  appearance: none;
  width: 100%;
  cursor: pointer;
}

.seeker-form .form-group input[type="date"] {
  color: #888;
}

.seeker-form .submit-button {
  width: 40%;
  background-color: #004aad;
  padding: 8px 15px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.seeker-form .submit-button:hover {
  background-color: #005bbb;
  transform: scale(1.05);
}

/* Waiting List Container */
.waiting-list-container {
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
}

.waiting-list-main {
  /* max-width: 1200px; */
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Buttons to switch between Seeker Form and Waiting List */
.waiting-list-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.waiting-list-options button {
  padding: 8px 15px;
  font-size: 1.1rem;
  background-color: #ff4b4b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 200px;
}

.waiting-list-options button:hover {
  background-color: #e04b2e;
}

.waiting-list-options button:focus {
  outline: none;
}

/* Waiting List Section */
.waiting-list {
  margin-top: 40px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.waiting-list h3 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
}

.waiting-list-item {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #f1f1f1;
  margin-bottom: 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.waiting-list-item:hover {
  background-color: #f9f9f9;
  transform: scale(1.02);
}

.waiting-list-item span {
  font-size: 1.2rem;
  font-weight: 500;
}

.waiting-list-item button:hover {
  background-color: #e04b2e;
}

.waiting-list-details {
  margin-bottom: 10px;
  font-size: 1rem;
}

.waiting-list-details strong {
  color: #333;
}

.register-message p {
  font-size: 1.1rem;
  color: #333;
  text-align: center;
}

.register-message .register-link {
  color: #004aad;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.register-message .register-link:hover {
  color: #002f6c;
}

/* Responsive Design for Tablets and Mobile Devices */
@media (max-width: 1024px) {
  .waiting-list-main {
    padding: 20px;
  }

  .waiting-list-options {
    gap: 15px;
  }

  .seeker-form {
    width: 90%;
  }

  .waiting-list-item {
    flex-direction: column;
    align-items: center;
  }

  .waiting-list-item span {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .waiting-list-options button {
    width: 100%;
  }

  .waiting-list-main {
    padding: 15px;
  }

  .seeker-form {
    width: 90%;
    padding: 25px;
  }

  .waiting-list-item button {
    width: 100%;
    padding: 12px;
  }

  /* Adjust Register Message */
  .register-message {
    padding: 15px;
    margin-top: 20px;
  }

  .register-message p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .waiting-list-options button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .seeker-form button {
    font-size: 1rem;
  }

  .waiting-list h3 {
    font-size: 1.3rem;
  }

  .waiting-list-item {
    padding: 10px;
  }

  .waiting-list-item button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  /* Further Adjust Register Message */
  .register-message {
    padding: 10px;
    margin-top: 15px;
  }

  .register-message p {
    font-size: 0.95rem;
  }
}
