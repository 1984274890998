.invoices-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  color: #555;
  font-weight: bold;
  text-align: center;
}
