/* Admin Dashboard General Styles */
.admin-dashboard {
  animation: fadeIn 0.5s ease-out forwards;
  display: flex;
  height: 100vh;
  background-color: #f5f5f5;
  overflow: hidden;
}

.admin-sidebar {
  width: 20%;
  min-width: 200px;
  max-width: 300px;
  background: black;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  position: fixed;
  height: 100%;
  z-index: 2;
}

.admin-sidebar h2 {
  text-align: center;
  margin-bottom: 20px;
}

.admin-sidebar ul {
  list-style: none;
  padding: 0;
}

.admin-sidebar ul li {
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
}

.admin-sidebar ul li.active {
  background: rgb(30, 29, 29);
}

.admin-main-content {
  flex: 1;
  margin-left: 20%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.admin-content {
  flex: 1;
  padding: 20px;
  overflow-x: auto;
  position: relative;
}

/* Common Table Styles */
.table-container {
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

table thead {
  background-color: #343a40;
  color: white;
}

table th,
table td {
  padding: 12px 16px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 0.9rem;
  word-wrap: break-word;
}

table th {
  text-transform: uppercase;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 1;
}

table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

/* Action Buttons */
.table-actions {
  display: flex;
  gap: 8px;
}

.table-actions button {
  padding: 6px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.85rem;
  transition: background-color 0.2s ease;
}

.table-actions button.edit-button {
  background-color: #28a745;
  color: white;
}

.table-actions button.edit-button:hover {
  background-color: #218838;
}

.table-actions button.delete-button {
  background-color: #dc3545;
  color: white;
}

.table-actions button.delete-button:hover {
  background-color: #c82333;
}

.table-actions button.view-button {
  background-color: #007bff;
  color: white;
}

.table-actions button.view-button:hover {
  background-color: #0056b3;
}

.action-button {
  margin: 2px;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-align: center;
}

.action-button:hover {
  background-color: #d63e3e;
  transform: scale(1.05);
}

/* Modal Styles */
.admin-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeIn 0.3s ease;
}

.admin-modal-content {
  background: white;
  padding: 20px 30px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  animation: scaleIn 0.2s ease;
}

/* Keyframes for Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}
