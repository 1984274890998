.add-restaurant {
  animation: fadeIn 0.5s ease-out forwards;
  /* background-color: #f9f9f9; */
  border-radius: 10px;
  width: 100%;
  /* padding: 10px; */
  box-sizing: border-box;
}

.add-restaurant h1 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.2;
}

.progress-indicator ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

.progress-indicator li {
  font-size: 14px;
  color: #ccc;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  transition: color 0.3s ease, font-size 0.3s ease;
}

.progress-indicator li:hover,
.progress-indicator li.active {
  color: #e53935;
  font-size: 16px;
}

.step-content {
  margin-top: 20px;
}

.restaurant-navigation-buttons {
  /* Absolutely position at bottom within .restaurant-images */
  display: flex;
  justify-content: space-between;
}

.prev-btn,
.next-btn {
  padding: 10px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
}

.prev-btn {
  background-color: #393939;
}
.prev-btn:hover {
  background-color: #555;
}

.next-btn {
  background-color: #393939;
}
.next-btn:hover {
  background-color: #555;
}

@media screen and (max-width: 768px) {
  .progress-indicator ul {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .restaurant-navigation-buttons {
    position: static; /* no absolute pinning on small screens */
    margin-top: 1rem;
    justify-content: center; /* or space-around, etc. */
  }
}

@media screen and (max-width: 480px) {
  .add-restaurant h1 {
    font-size: 18px;
  }

  .progress-indicator li {
    font-size: 12px;
  }

  .restaurant-navigation-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .prev-btn,
  .next-btn {
    width: 100%;
    margin-bottom: 1rem;
  }
}
