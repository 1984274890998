.footer {
  background-color: #f9f9f9;
  color: black;
  padding: 20px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}

.footer-container {
  max-width: 2000px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-social {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid black;
  padding-bottom: 15px;
}

.footer-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 15px;
  font-size: xx-large;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icon {
  font-size: 1.5rem;
  color: black;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #ff4b4b; /* Hover color for icons */
}

.divider {
  height: 40px;
  width: 1px;
  background-color: black;
  margin: 0 20px;
}

.join-us {
  display: flex;
  align-items: center;
  gap: 10px;
}

.email-subscribe {
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}

.email-subscribe input {
  padding: 10px;
  width: 200px;
  font-size: 1rem;
  outline: none;
  background-color: #f9f9f9;
  color: black;
}

.email-subscribe input::placeholder {
  color: #a6a6a6;
}

.email-subscribe button {
  background-color: #393939;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 10px 15px;
  cursor: pointer;
}

.email-subscribe button:hover {
  background-color: black;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 40px;
}

.footer-logo img {
  max-width: 325px;
  margin-bottom: 10px;
}

.footer-section h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li a {
  color: black;
  text-decoration: none;
  font-size: 1rem;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid black;
  padding-top: 15px;
  font-size: 0.9rem;
  align-items: center;
}

.footer-policy a {
  color: black;
  text-decoration: none;
  margin: 0 10px;
}

.footer-policy a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* padding: 20px; */
    width: 100%;
  }

  .footer-social {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    height: auto;
    margin-top: 0;
    font-size: 1rem;
    text-align: center;
  }
  .footer-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 15px;
    font-size: medium;
  }

  .social-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .join-us {
    margin: 15px 0;
    margin-top: 0;
    font-size: 1.3rem;
    text-align: center;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
    margin-top: 0;
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
    width: 100%;
  }

  .footer-logo {
    display: none;
  }

  .divider {
    display: none;
  }
}

@media (max-width: 1024px) {
  .footer-social {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    height: auto;
    margin-top: 0;
    font-size: 1rem;
    text-align: center;
  }

  .divider {
    display: none;
  }
}
