.profile-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.profile-content {
  display: flex;
  flex-grow: 1;
  padding: 5px 0;
}

.sidebar {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex-shrink: 0;
}

.main-content {
  flex-grow: 1;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-left: 10px;
  flex-direction: column;
}

.next-btn,
.prev-btn {
  align-items: center;
  background-color: black;
  color: white;
  padding: 12px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  margin-right: 10px;
}

.next-btn:hover,
.prev-btn:hover {
  background-color: #555;
}

.next-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .profile-content {
    flex-direction: column;
  }

  .sidebar {
    margin-bottom: 5px;
    width: 100%;
  }

  .main-content {
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .field-group {
    flex-direction: column;
    width: 100%;
  }

  .main-content {
    background-color: #fff;
  }

  .image-preview-container {
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }

  .image-preview {
    width: 120px;
    height: 120px;
  }

  .image-entry {
    flex-direction: column;
  }

  .images-form-group {
    flex: 1 1 100%;
  }
}

@media screen and (max-width: 480px) {
  .kitchen-images h2 {
    font-size: 22px;
  }

  .image-preview-container {
    gap: 8px;
  }

  .image-preview {
    width: 100px;
    height: 100px;
  }

  .add-image-btn {
    padding: 10px 16px;
    font-size: 14px;
  }
}
