.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


a {
  color: #E63946;
}

a:hover {
  color: #C92C35;
}