.browse-page {
  width: 100%;
  overflow-x: hidden;
  font-family: "Nunito Sans", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.hero-section {
  width: 100%;
  background: linear-gradient(to bottom, rgb(0 0 0 / 90%), white);
  color: #fff;
  padding: 40px 20px;
  text-align: center;
  position: relative;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
}

.hero-title {
  font-size: 2.7rem;
  margin: 0px;
  font-weight: 700;
  filter: drop-shadow(4px 8px 12px rgb(0, 0, 0));
}

.hero-subtitle {
  font-size: 1.8rem;
  margin: 0px;
  margin-bottom: 40px;
  font-weight: 400;
  filter: drop-shadow(4px 8px 12px black);
}

.mobile-break {
  display: none;
}

.browse-search-bar {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  gap: 15px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  backdrop-filter: blur(10px);
  transition: all 0.5s ease-in-out;
  z-index: 1000;
}

.sticky {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1001;
  padding: 5px;
  background: linear-gradient(to bottom, rgba(118, 116, 116, 0.9), white);
  transition: top 0.3s ease, background 0.3s ease;
}

.autocomplete-container {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
}

.autocomplete-container input[type="text"] {
  border: none !important;
  outline: none;
  font-size: 14px;
  color: #333;
  background: none;
  flex: 1;
  border-radius: 25px;
}

.autocomplete-container input::placeholder {
  color: #888;
}

.geo-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e63946;
  color: #ffffff;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-right: 10px;
}

.geo-button:hover {
  background-color: #d62828;
  transform: translateY(-2px);
}

.geo-button:active {
  background-color: #b4161b;
}

.geo-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.spinner {
  width: 18px;
  height: 18px;
  border: 3px solid rgba(255, 255, 255, 0.6);
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.browse-search-button {
  background-color: #b0b0b0;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.browse-search-button:hover {
  background-color: rgb(44, 44, 44);
  transform: translateY(-2px);
}

.action-buttons {
  display: inline-flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
  margin-bottom: 8px;
  transition: transform 0.8s ease-in-out;
  position: relative;
  right: 0;
}

.scrolled {
  right: 100px;
  top: -3px;
}

.filter-button,
.map-button {
  background-color: #e63946;
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.filter-button:hover,
.map-button:hover {
  background-color: #d62828;
  transform: translateY(-2px);
}

.filter-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-modal-content {
  background-color: #fff;
  width: 90%;
  max-width: 600px;
  border-radius: 5px;
  padding: 30px 25px;
  position: relative;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  animation: slideUp 0.5s ease forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.filter-header h2 {
  margin: 0;
  font-size: 1.8rem;
  color: #333;
}

.close-modal-button {
  background: none;
  border: none;
  border-radius: 25%;
  font-size: 2rem;
  cursor: pointer;
  color: #666;
  transition: color 0.3s;
}

.close-modal-button:hover {
  color: #e63946;
  background-color: #f2ecec;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters select {
  border: 1px solid #ccc;
  outline: none;
  padding: 12px 15px;
  border-radius: 10px;
  font-size: 16px;
  background-color: #f9f9f9;
  transition: border-color 0.3s, background-color 0.3s;
}

.filters select:focus {
  border-color: #e63946;
  background-color: #fff;
}

.date-time-filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.date-time-filters label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.date-picker-input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s, background-color 0.3s;
}

.date-picker-input:hover {
  background-color: #fff;
}

.date-picker-input:focus {
  border-color: #e63946;
  background-color: #fff;
}

.modal-actions {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.apply-filters-button {
  background-color: #e63946;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 12px 25px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.apply-filters-button:hover {
  background-color: #d62828;
  transform: translateY(-2px);
}

.kitchen-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  width: 90%;
  margin: 0 auto;
}

.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: #555;
}

.no-results p {
  margin-top: 15px;
  font-size: 1.2rem;
}

.map-section .map-container {
  width: 90%;
  margin: 10px auto;
  height: 500px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 1px 5px 20px rgba(71, 173, 228, 0.1);
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  text-align: center;
  color: #333;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(227, 57, 70, 0.3);
  border-top: 5px solid #e63946;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

.loading-text {
  font-size: 1.5rem;
  font-weight: 500;
  color: #555;
  animation: fadeIn 1.5s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 35px 15px;
  }

  .hero-title {
    font-size: 2.2rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
  }

  .mobile-break {
    display: block;
  }

  .browse-search-bar {
    align-items: stretch;
    gap: 10px;
    max-width: 100%;
  }

  .browse-search-button {
    width: 35px;
    height: 35px;
    justify-content: center;
  }

  .geo-button {
    width: 30px;
    height: 30px;
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 75%;
    margin: 0 auto;
    padding: 8px 0;
  }

  .filter-button,
  .map-button {
    max-width: 400px;
  }

  .filter-modal-content {
    padding: 25px 20px;
  }

  .kitchen-list {
    gap: 15px;
    width: 95%;
    margin: 0 auto;
  }

  .map-section .map-container {
    height: 500px;
    width: 95%;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 1.5rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  .mobile-break {
    display: block;
  }

  .apply-filters-button {
    width: 100%;
  }

  .kitchen-list {
    gap: 15px;
    width: 95%;
    margin: 0 auto;
  }
  .map-section .map-container {
    height: 500px;
    width: 95%;
  }
}
