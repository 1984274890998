.signup-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  animation: fadeIn 0.3s ease;
  overflow: auto; 
}

.signup-modal__container {
  background-color: #ffffff;
  padding: 50px 40px;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slideDown 0.3s ease;
  overflow: hidden;
}

.signup-modal__close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.8rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #888888;
  transition: color 0.3s ease;
}

.signup-modal__close-btn:hover {
  color: #555555;
}

.signup-modal__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

.signup-modal__logo-link {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.signup-modal__logo-image {
  width: 100%;
  max-width: 100px;
  height: auto;
}

.signup-modal__title {
  font-size: 1.8rem;
  color: #333333;
  margin: 0;
  text-align: center;
}

.signup-modal__error-message,
.signup-modal__success-message {
  width: 100%;
  text-align: center;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-weight: bold;
}

.signup-modal__error-message {
  color: #e63946;
  background-color: #ffe6e6;
}

.signup-modal__success-message {
  color: #2a9d8f;
  background-color: #e6f4f1;
}


.signup-modal__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.signup-modal__form-group {
  width: 100%;
  margin-bottom: 20px;
}

.signup-modal__input {
  width: 100%;
  padding: 14px 16px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.signup-modal__input:focus {
  border-color: #2a9d8f;
  box-shadow: 0 0 5px rgba(42, 157, 143, 0.3);
  outline: none;
}

.signup-modal__signup-btn {
  width: 100%;
  padding: 14px 16px;
  background-color: #2a9d8f;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.signup-modal__signup-btn:hover {
  background-color: #21867a;
  transform: translateY(-2px);
}

.signup-modal__signup-btn:active {
  transform: translateY(0);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideDown {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@media (max-width: 480px) {
  .signup-modal__container {
    padding: 30px 25px;
    max-width: 90%;
  }

  .signup-modal__logo-image {
    max-width: 80px;
  }

  .signup-modal__title {
    font-size: 1.5rem;
  }

  .signup-modal__input {
    padding: 12px 14px;
    font-size: 0.95rem;
  }

  .signup-modal__signup-btn {
    padding: 12px 14px;
    font-size: 0.95rem;
  }
}
