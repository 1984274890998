.my-reservations-container {
  animation: fadeIn 0.5s ease-out forwards;
  height: 100%;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.my-reservations-container h1 {
  text-align: center;
  color: #333;
  font-size: 28px;
  margin-bottom: 30px;
  font-weight: bold;
}

.reservations-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reservation-item {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  gap: 20px;
}

.reservation-details {
  flex: 2;
  padding: 0 20px;
  box-sizing: border-box;
}

.reservation-details h3 {
  font-size: 22px;
  color: #333;
  margin-bottom: 8px;
}

.reservation-details p {
  margin: 5px 0;
  font-size: 14px;
  line-height: 1.4;
}

.reservation-price {
  flex-shrink: 0;
  min-width: 120px;
}

.reservation-price p {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

.status {
  font-weight: bold;
  color: #28a745;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-size: 18px;
  color: #555;
  font-weight: bold;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .my-reservations-container {
    padding: 0%;
    background-color: whitesmoke;
    box-shadow: none;
  }

  .my-reservations-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .reservation-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    gap: 10px;
  }

  .reservation-details {
    padding: 0%;
  }

  .reservation-details h3 {
    font-size: 18px;
    margin-bottom: 6px;
  }

  .reservation-details p,
  .reservation-price p {
    font-size: 13px;
  }

  .reservation-price {
    width: 100%;
  }

  .reservation-price p {
    font-size: 13px;
  }
  
  .status {
    font-size: 13px;
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .my-reservations-container {
    padding: 0%;
  }

  .my-reservations-container h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .reservation-item {
    padding: 10px;
  }

  .reservation-details h3 {
    font-size: 16px;
  }

  .reservation-details p,
  .reservation-price p {
    font-size: 12px;
  }

  .status {
    font-size: 12px;
  }
}