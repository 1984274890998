.new-kitchen-details {
  animation: fadeIn 0.5s ease-out forwards;
  background-color: white;
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.char-limit {
  font-size: 14px;
  color: #767575;
  margin-top: 3px;
}

.terms-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.terms-container input[type="checkbox"] {
  accent-color: #393939;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  transition: box-shadow 0.3s ease;
}

.terms-container input[type="checkbox"]:checked {
  box-shadow: 0 0 8px #393939;
}

.terms-container label {
  margin-left: 8px;
  font-size: 14px;
}

.kitchen-terms-link {
  color: #393939;
  text-decoration: underline;
  cursor: pointer;
}

.kitchen-terms-link:hover {
  color: black;
}

.loading-indicator {
  margin-top: 10px;
  color: #666;
  font-size: 14px;
  text-align: center;
}

.tc-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.tc-modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  height: 70vh;
  max-height: 70vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tc-modal-content h3 {
  margin-top: 0;
  color: #333;
  font-size: 20px;
}

.tc-modal-body {
  margin-top: 15px;
  color: #666;
}

.tc-modal-close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  color: #393939;
  cursor: pointer;
  transition: color 0.3s;
}

.tc-modal-close-icon:hover {
  color: black;
}

@media (max-width: 768px) {
  .new-kitchen-details {
    padding: 0%;
    border-radius: 0%;
    box-shadow: none;
  }

  .tc-modal-content {
    width: 90%;
    height: 80vh;
    max-height: 80vh;
    padding: 15px;
  }

  .tc-modal-content h3 {
    font-size: 18px;
  }

  .tc-modal-close-icon {
    top: 10px;
    right: 10px;
    font-size: 22px;
  }
}
