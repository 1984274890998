/* 
  1) Keyframes: fade + slide up
     (starts slightly below, fades in and moves up)
*/
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
  2) MAIN CONTAINER: .restaurant-hours
*/
.restaurant-hours {
  /* Replaces "fadeIn" with our new fadeInUp keyframe */
  animation: fadeIn 0.5s ease-out forwards;
  background-color: white;
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Heading */
.restaurant-hours h2 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.2;
}

/* Labels */
.restaurant-hours label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #555;
}

/* Text + DatePickers */
.restaurant-hours input[type="text"],
.restaurant-hours .react-datepicker-wrapper {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 16px;

  /* Subtle transition for focus/hover if you want */
  transition: border-color 0.3s ease;
}

.restaurant-hours input[type="text"]:hover,
.restaurant-hours input[type="text"]:focus,
.restaurant-hours
  .react-datepicker-wrapper:hover
  .react-datepicker__input-container
  input,
.restaurant-hours
  .react-datepicker-wrapper:focus-within
  .react-datepicker__input-container
  input {
  border-color: #999;
}

/* Number input (Days Before) */
.restaurant-hours input[type="number"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.restaurant-hours input[type="number"]:hover,
.restaurant-hours input[type="number"]:focus {
  border-color: #999;
}

/* Time-picker row if used */
.restaurant-hours .time-picker {
  display: flex;
  gap: 15px;
  align-items: center;
}

.restaurant-hours .time-picker span {
  font-size: 14px;
  color: #555;
}

/* Button group container */
.restaurant-hours .button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/*
  3) RESPONSIVE BREAKPOINTS
*/

/* Tablets and small desktops */
@media screen and (max-width: 768px) {
  .restaurant-hours {
    padding: 15px;
    box-shadow: none;
    border-radius: 0%;
  }

  .restaurant-hours h2 {
    font-size: 24px;
  }

  /* Adjust input and button font sizes a bit smaller */
  .restaurant-hours input[type="text"],
  .restaurant-hours .react-datepicker-wrapper,
  .restaurant-hours input[type="number"] {
    font-size: 14px;
  }
}

/* Mobile phones */
@media screen and (max-width: 480px) {
  .restaurant-hours {
    padding: 10px;
  }

  .restaurant-hours h2 {
    font-size: 20px;
  }

  .restaurant-hours input[type="text"],
  .restaurant-hours .react-datepicker-wrapper,
  .restaurant-hours input[type="number"] {
    font-size: 12px;
  }
}
