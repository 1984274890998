.my-kitchens-container {
  animation: fadeIn 0.5s ease-out forwards;
  padding: 10px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  box-sizing: border-box;
}

.my-kitchens-container h1 {
  text-align: left;
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}

.kitchen-header-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.kitchens-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.kitchen-item {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  gap: 20px;
  transition: box-shadow 0.3s;
}

.kitchen-item:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.kitchen-image {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
}

.kitchen-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.kitchen-details {
  flex: 1;
  justify-content: space-between;
}

.kitchen-details h3 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0 0 10px;
}

.kitchen-details p {
  margin: 8px 0;
  font-size: 14px;
  color: #666;
  line-height: 1.4;
}

.kitchen-details .location {
  color: #666;
  font-weight: bold;
  margin-top: 10px;
}

.kitchen-price-status {
  text-align: right;
}

.kitchen-price-status .price {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.kitchen-price-status .rating {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #999;
  margin-top: 5px;
}

.kitchen-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.kitchen-actions .edit-btn,
.kitchen-actions .delete-btn,
.kitchen-actions .archive-btn {
  text-decoration: none;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.kitchen-actions .edit-btn {
  background-color: #e53935;
}

.kitchen-actions .delete-btn {
  background-color: #5f6368;
}

.kitchen-actions .archive-btn {
  background-color: #393939;
}

.kitchen-actions .edit-btn:hover,
.kitchen-actions .delete-btn:hover,
.kitchen-actions .delete-btn:hover {
  opacity: 0.9;
}

.badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
}

.badge-draft {
  background-color: #f4b400;
}

.badge-approve {
  background-color: #0f9d58;
}

.badge-archive {
  background-color: #db4437;
}

.kitchen-title-status .badge {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
  line-height: 1;
}

.loading-kitchen {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #555;
  font-weight: bold;
  text-align: center;
}

.bottom-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.add-kitchen-btn {
  text-decoration: none;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #393939;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.add-kitchen-btn:hover {
  background-color: #000;
  transform: translateY(-2px);
  color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-content {
    box-shadow: none;
  }

  .my-kitchens-container {
    box-shadow: none;
    padding: 0%;
    margin: 0%;
    background-color: whitesmoke;
    box-shadow: none;
  }

  .kitchen-header-1 {
    display: flex;
    flex-direction: column;
  }
  .kitchen-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .kitchen-image {
    width: 100%;
    height: auto;
  }

  .kitchen-image img {
    height: auto;
    max-height: 200px;
  }

  .kitchen-details h3 {
    font-size: 16px;
    margin: 10px 0;
  }

  .kitchen-details p {
    font-size: 13px;
  }

  .kitchen-price-status {
    width: 100%;
    text-align: left;
    margin-top: 10px;
  }

  .kitchen-price-status .price {
    font-size: 18px;
  }

  .kitchen-actions {
    width: 100%;
    justify-content: space-between;
  }

  .kitchen-actions .edit-btn,
  .kitchen-actions .archive-btn {
    flex: 1;
    text-align: center;
    padding: 10px;
    font-size: 13px;
  }

  .kitchen-title-status h3 {
    font-size: 15px;
  }

  .kitchen-title-status .badge {
    font-size: 10px;
    padding: 2px 5px;
  }
}

@media (max-width: 480px) {
  .my-kitchens-container h1 {
    font-size: 20px;
    margin-left: 10px;
  }

  .kitchen-details h3 {
    font-size: 15px;
  }

  .kitchen-details p {
    font-size: 12px;
  }

  .kitchen-price-status .price {
    font-size: 16px;
  }

  .kitchen-actions .edit-btn,
  .kitchen-actions .archive-btn {
    font-size: 12px;
    padding: 8px;
  }
}
