.features-section {
  padding: 50px 20px;
  text-align: center;
  background-color: #faf3f5;
  font-family: "Nunito Sans", sans-serif;
}

.features-section h2 {
  font-size: 2.4rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 30px;
}

/* Feature Buttons */
.feature-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.feature-button {
  background-color: transparent;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 1.1rem;
  color: #333;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.4s ease;
  border: none;
}

.feature-button:hover {
  color: black;
  font-weight: 700;
  transform: scale(1.2);
}

.feature-button.active {
  color: black;
  font-weight: 700;
  transform: scale(1.2);
}


/* Feature Content */
.feature-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  max-width: 1100px;
  margin: 0 auto;
  text-align: left;
}

.feature-image {
  width: 100%;
  max-width: 400px;
  height: auto; 
  aspect-ratio: 4 / 3; 
  border-radius: 0 40px 0 40px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.15);
  object-fit: cover;
}

.feature-description {
  max-width: 45%;
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.feature-description p {
  margin: 0;
  font-size: 18px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .feature-content {
    flex-direction: column;
    align-items: center;
  }

  .feature-image {
    width: 80%;
  }

  .feature-description {
    max-width: 80%;
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .features-section h2 {
    font-size: 2rem;
  }

  .feature-button {
    font-size: 1rem;
    padding: 8px 16px;
  }

  .feature-image {
    width: 90%;
  }

  .feature-description {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .feature-buttons {
    /* flex-direction: column; */
    gap: 10px;
  }

  .feature-button {
    font-size: 0.9rem;
    padding: 10px;
  }

  .feature-description p {
    font-size: 0.9rem;
  }
}
