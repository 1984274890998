.TOU-main-container {
  animation: fadeIn 0.5s ease-out forwards;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f7f7f7;
}

.TOU-container {
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
}

.TOU-container h2 {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.TOU-effective-date {
  font-size: 1rem;
  color: #888;
  text-align: center;
  margin-bottom: 30px;
}

.TOU-content p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.8;
  margin-bottom: 20px;
}

.TOU-content ol {
  padding-left: 20px;
}

.TOU-content li {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.8;
  margin-bottom: 20px;
}

.TOU-content li strong {
  color: #ff4b4b;
  font-weight: bold;
}

.TOU-content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 10px;
}

.TOU-content ul li {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.TOU-contact-email {
  color: #ff4b4b;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.TOU-contact-email:hover {
  color: #e04b2e;
  text-decoration: underline;
}

/* Responsive Design */
@media (min-width: 1024px) {
  .TOU-container {
    padding: 50px 30px;
    max-width: 900px;
  }

  .TOU-container h2 {
    font-size: 3rem;
  }

  .TOU-content p,
  .TOU-content li {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .TOU-container {
    padding: 40px 20px;
  }

  .TOU-container h2 {
    font-size: 2.25rem;
  }

  .TOU-content p,
  .TOU-content li {
    font-size: 1.1rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .TOU-container {
    padding: 30px 15px;
  }

  .TOU-container h2 {
    font-size: 2rem;
  }

  .TOU-content p,
  .TOU-content li {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .TOU-container {
    padding: 20px 10px;
  }

  .TOU-container h2 {
    font-size: 1.75rem;
  }

  .TOU-content p,
  .TOU-content li {
    font-size: 0.95rem;
  }

  .TOU-effective-date {
    font-size: 0.9rem;
  }
}

@media (max-width: 360px) {
  .TOU-container {
    padding: 15px 5px;
  }

  .TOU-container h2 {
    font-size: 1.5rem;
  }

  .TOU-content p,
  .TOU-content li {
    font-size: 0.85rem;
  }

  .TOU-effective-date {
    font-size: 0.85rem;
  }
}
