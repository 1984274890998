.blogs-page {
  animation: fadeIn 0.5s ease-out forwards;
  padding: 20px;
  max-width: 1200px;
  max-height: 100vh;
  margin: 0 auto;
  background-color: #f8f9fa;
  min-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blogs-page h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 2.5rem;
}

.blogs-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.no-blogs-message {
  text-align: center;
  font-size: 1.5rem;
  color: #555;
  padding: 50px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-card {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-content {
  padding: 15px;
  text-align: center;
}

.blog-title {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 10px;
}

.blog-date {
  font-size: 0.9rem;
  color: #555;
}

.loading-indicator {
  font-size: 1.5rem;
  color: #555;
  text-align: center;
  margin: 100px 0;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .blogs-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .blogs-grid {
    grid-template-columns: 1fr;
  }

  .blog-image {
    height: 150px;
  }

  .blog-title {
    font-size: 1.2rem;
  }
}
