@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap");

:root {
  --primary-color: #e63946;
  --secondary-color: #2c3e50;
  --background-color: #f9f9f9;
  --text-color: #2c3e50;
  --light-text-color: #6c757d;
  --border-color: #ddd;
  --success-color: #28a745;
  --error-color: #dc3545;
  --modal-overlay-bg: rgba(0, 0, 0, 0.5);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Nunito Sans", sans-serif;
  margin: 0;
  padding: 0;
}

input:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
textarea:focus,
select:focus {
  border: 2px solid black;
  outline: none;
}

.pac-container {
  font-family: "Nunito Sans", sans-serif;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1050;
}

.pac-item {
  padding: 5px 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pac-item:hover {
  background-color: #f5f5f5;
  color: #000;
}

.pac-item-query {
  font-weight: 600;
  color: #000;
}

@media screen and (max-width: 768px) {
  .pac-container {
    max-width: 100%;
  }

  .pac-item {
    font-size: 12px;
    padding: 8px 12px;
  }
}
