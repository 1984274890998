/* 
  1) KEYFRAMES: Fade + slide up
     Starts below (translateY) and fully fades in.
*/
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
  2) CONTAINER: .restaurant-location
*/
.restaurant-location-l {
  /* FadeInUp animation (0.6s, ease-out) */
  animation: fadeIn 0.5s ease-out forwards;
  background-color: white;
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

/*
  3) HEADING
*/
.restaurant-location-l h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 25px;
  color: #333;
  text-align: center;
}

/*
  4) FORM GROUPS
*/
.form-group {
  margin-bottom: 20px;
}

/*
  5) INPUT WITH ICON
*/
.input-with-icon {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: #888;
  pointer-events: none;
}

.input-with-icon input {
  padding: 12px 12px 12px 40px; /* space for the icon */
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;

  /* Add a focus/hover transition */
  transition: border-color 0.3s ease;
}

.input-with-icon input:hover,
.input-with-icon input:focus {
  border-color: #999; /* subtle highlight */
}

/*
  6) MAP VIEW
*/
.map-view {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .restaurant-location-l {
    padding: 15px;
    margin: 15px auto;
    box-shadow: none;
    border-radius: 0%;
  }

  .restaurant-location-l h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .input-with-icon input {
    font-size: 14px;
  }

  .navigation-buttons button {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .restaurant-location-l {
    padding: 10px;
  }

  .restaurant-location-l h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .input-with-icon input {
    font-size: 13px;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .navigation-buttons button {
    width: 100%;
    margin-bottom: 10px;
  }
}
