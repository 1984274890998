/* Reset some default styles */
.ai-builder-container,
.ai-builder-main-content,
.features-vertical,
.aifeature {
  box-sizing: border-box;
}

.ai-builder-container {
  animation: fadeIn 0.5s ease-out forwards;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
  font-family: "Nunito Sans", sans-serif;
}

.page-title {
  text-align: center;
  margin: 20px 0;
  font-size: 2rem;
  color: #333;
}

.ai-builder-main-content {
  flex: 1;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.features-vertical {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}

/* Feature Card Styling */
.aifeature {
  background-color: #f8f9fa;
  padding: 30px; 
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aifeature:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.aifeature h2 {
  margin-bottom: 20px;
  color: #d9534f;
  font-size: 1.75rem;
  text-align: center;
}

.aifeature p {
  text-align: center;
  color: #555;
  font-size: 1.1rem;
}

.textarea-container {
  position: relative;
  width: 100%;
  margin-bottom: 10px; 
}

.prompt-box,
.result-box {
  width: 100%;
  padding: 15px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  font-size: 1rem;
  font-family: inherit;
  transition: border-color 0.3s ease;
}

.prompt-box:focus,
.result-box:focus {
  border-color: #d9534f;
  outline: none;
}

.generate-button,
.download-button {
  width: 40%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #d9534f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.generate-button:disabled,
.download-button:disabled {
  background-color: #a94442;
  cursor: not-allowed;
}

.generate-button:hover:not(:disabled),
.download-button:hover:not(:disabled) {
  background-color: #c9302c;
}

.loading-text {
  text-align: center;
  color: #d9534f;
  margin-top: 10px;
}

.coming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center; 
}

/* Clear Button Styling (Optional) */
.clear-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: transparent;
  border: none;
  color: #999;
  font-size: 18px;
  cursor: pointer;
}

.clear-button:hover {
  color: #333;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .ai-builder-main-content {
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .page-title {
    font-size: 1.75rem;
  }

  .aifeature h2 {
    font-size: 1.5rem;
  }

  .aifeature p {
    font-size: 1rem;
  }

  .prompt-box,
  .result-box {
    font-size: 0.95rem;
    padding: 12px 15px;
  }

  .generate-button,
  .download-button {
    font-size: 0.95rem;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .page-title {
    font-size: 1.5rem;
  }

  .aifeature h2 {
    font-size: 1.25rem;
  }

  .aifeature p {
    font-size: 0.95rem;
  }

  .prompt-box,
  .result-box {
    font-size: 0.9rem;
    padding: 10px 12px;
  }

  .generate-button,
  .download-button {
    font-size: 0.8rem;
    padding: 10px;
  }
}
