.CareerPage-main-container {
  font-family: "Nunito Sans", sans-serif;
  background-color: #f7f7f7;
  color: #333;
  animation: fadeInPage 1s ease-in-out forwards;
}

/* Content Wrapper */
.CareerPage-container {
  padding: 20px 20px;
  max-width: 1200px;
  min-height: 85vh;
  margin: 0 auto;
  box-sizing: border-box;
  animation: fadeIn 0.5s ease-out forwards;
}

/* Section 1: Header Section */
.career-section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.career-section-left {
  flex: 1;
  padding-right: 20px;
  animation: slideInLeft 1s ease-in-out forwards;
}

.career-section-left h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #2c3e50;
}

.career-section-left p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
  animation: slideInLeft 1s ease forwards;
}

.career-page-button {
  background-color: #393939;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  animation: fadeInButton 1.2s ease forwards;
}

.career-page-button:hover {
  background-color: #4a4a4a;
  transform: translateY(-3px);
}

.career-section-right {
  flex: 1;
  text-align: center;
  animation: zoomInImage 1s ease forwards;
}

.career-section-right img {
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

/* Section 2: Job Categories */
.career-section-2 {
  margin-top: 50px;
  animation: fadeInUp 1s ease forwards;
}

.career-section-2 h2 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #2c3e50;
}
/* Job Categories */
.job-categories {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 10px;
}

.job-categories::-webkit-scrollbar {
  height: 6px;
}

.job-categories::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

.job-categories::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.job-category {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
}

.job-category button {
  background-color: #393939;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 3px;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  white-space: nowrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.job-category button:hover {
  background-color: #4a4a4a;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

.job-category button:active {
  background-color: #333333;
  transform: scale(0.97);
}

/* Responsive Design for Job Categories */
@media (max-width: 768px) {
  .career-section-1 {
    flex-direction: column;
    text-align: center;
  }

  .career-section-left {
    padding-right: 0;
  }

  .CareerPage-job-title {
    font-size: 1.5rem !important;
  }

  .job-categories {
    justify-content: flex-start;
  }

  .job-category button {
    font-size: 1.2rem;
    padding: 10px 15px;
  }

  .CareerPage-apply-btn {
    font-size: 1rem !important;
  }
}

@media (max-width: 480px) {
  .job-categories {
    overflow-x: scroll;
    justify-content: center;
    gap: 10px;
  }

  .job-category button {
    font-size: 0.9rem;
    padding: 8px 12px;
    min-width: 100px;
  }

  .career-section-left h2 {
    font-size: 2rem;
  }

  .job-category {
    flex: 1 1 100%;
  }

  .career-page-button {
    padding: 8px 12px;
  }

  .CareerPage-job-title {
    font-size: 1.4rem;
  }
}

.CareerPage-job-list {
  margin-top: 10px;
}

.CareerPage-job-item {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  margin-bottom: 20px;
}

.CareerPage-job-item:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.CareerPage-job-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 10px;
}

.CareerPage-job-details {
  font-size: 1rem;
  color: #555;
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.CareerPage-job-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-top: 15px;
}

.CareerPage-tab-panel h3 {
  font-size: 1.4rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 10px;
}

.CareerPage-tab-panel p,
.CareerPage-tab-panel ul {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.CareerPage-tab-panel ul {
  list-style-type: disc;
}

.CareerPage-tab-panel ul li {
  margin-bottom: 10px;
}

/* Apply Button */
.CareerPage-apply-btn {
  background-color: #393939;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 4px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100%;
  max-width: 200px;
}

.CareerPage-apply-btn:hover {
  background-color: #4a4a4a;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.CareerPage-apply-btn:active {
  background-color: #333333;
  transform: scale(0.98);
}

/* Animations */
@keyframes fadeInPage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoomInImage {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInButton {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
