.faq-container {
  animation: fadeIn 0.5s ease-out forwards;
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
}

.faq-container h2 {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.faq-message {
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

.faq-message strong {
  color: #ff4b4b;
}

.faq-description {
  font-size: 1.1rem;
  color: #555;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.6;
}

.faq-description strong {
  color: #ff4b4b;
}

.faq-list {
  margin-top: 20px;
}

.faq-item {
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  transition: background-color 0.3s ease;
}

.faq-item:hover {
  background-color: #f1f1f1;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  color: #333;
}

.faq-question:hover {
  background-color: #f1f1f1;
}

/* Highlight selected question */
.faq-question.selected {
  background-color: #ff4b4b;
  color: white;
  border-radius: 5px;
}

/* Arrow */
.arrow {
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

/* Smooth dropdown animation */
.faq-answer {
  padding: 0 15px;
  background-color: #f1f1f1;
  border-left: 3px solid #ff4b4b;
  font-size: 1rem;
  color: #555;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}

.faq-answer.show {
  max-height: 500px;
  padding: 10px 15px;
}

/* Got more questions section */
.faqs-got-questions {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;
}

.faqs-got-questions p {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.get-in-touch-btn {
  padding: 12px 25px;
  font-size: 1.1rem;
  color: white;
  background-color: #ff4b4b;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  margin-top: 20px;
}

.get-in-touch-btn:hover {
  background-color: #e04b2e;
}

/* Mobile and Tablet responsiveness */
@media (max-width: 1024px) {
  .faq-container {
    padding: 30px 15px;
  }

  .faq-container h2 {
    font-size: 2rem;
  }

  .faq-question {
    font-size: 1.1rem;
  }

  .arrow {
    font-size: 1.3rem;
  }

  .faq-answer {
    font-size: 0.95rem;
  }

  .faqs-got-questions p {
    font-size: 1.1rem;
  }

  .get-in-touch-btn {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

@media (max-width: 768px) {
  .faq-container {
    padding: 20px 10px;
  }

  .faq-container h2 {
    font-size: 1.75rem;
  }

  .faq-question {
    font-size: 1rem;
    padding: 12px;
  }

  .arrow {
    font-size: 1.2rem;
  }

  .faq-answer {
    font-size: 0.9rem;
  }

  .faqs-got-questions p {
    font-size: 1rem;
  }

  .get-in-touch-btn {
    font-size: 1rem;
    padding: 12px 25px;
  }
}

@media (max-width: 480px) {
  .faq-container {
    padding: 15px 5px;
  }

  .faq-container h2 {
    font-size: 1.5rem;
  }

  .faq-question {
    font-size: 0.9rem;
    padding: 10px;
  }

  .faq-message {
    font-size: 1.2rem;
  }

  .faq-description {
    font-size: 1rem;
  }

  .get-in-touch-btn {
    font-size: 1rem;
    padding: 12px 25px;
  }
}
