.profile-details {
  animation: fadeIn 0.5s ease-out forwards;
  padding: 40px;
  height: 100%;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.profile-heading {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 30px;
}

.profile-picture-section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.profile-picture-container {
  position: relative;
  width: 150px;
  height: 150px;
  cursor: pointer;
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #393939;
  transition: opacity 0.3s ease;
}

.profile-picture-container:hover .profile-picture {
  opacity: 0.8;
}

.edit-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #393939;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  font-size: 30px;
  margin-right: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-icon:hover {
  background-color: black;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.form-group {
  flex: 1;
  min-width: 250px;
}

.profile-details label {
  display: block;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 6px;
  color: #333;
}

.profile-details input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.password-btn {
  display: block;
  background-color: #393939;
  color: white;
  padding: 12px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  max-width: 150px;
  text-align: center;
  margin: 0 auto;
  transition: background-color 0.3s ease;
}

.password-btn:hover {
  background-color: black;
}

.update-btn {
  background-color: #393939;
  color: white;
  padding: 12px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  max-width: 150px;
  text-align: center;
  margin: 0 auto;
  transition: background-color 0.3s ease;
}

.update-btn:hover {
  background-color: black;
}

.password-form {
  margin-top: 20px;
}
.success-message {
  background-color: #d4edda;
  color: #155724;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
}

.profile-error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #555;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 0;
  }

  .form-group {
    width: 100%;
    min-width: unset;
  }

  .profile-picture-container {
    width: 120px;
    height: 120px;
  }

  .edit-icon {
    font-size: 26px;
    padding: 4px;
    margin-right: 15px;
  }
}
