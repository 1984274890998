.contact-page {
  font-family: "Nunito Sans", sans-serif;
  background: linear-gradient(135deg, #f7f7f7, #ffffff);
  color: #2c3e50;
  padding: 10px 20px;
  animation: fadeInPage 1s ease-in-out forwards;
}

/* Header */
.contact-header {
  text-align: center;
  margin-bottom: 40px;
}
.contact-header h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #2c3e50;
}
.contact-header p {
  font-size: 1.2rem;
  color: #555;
}

/* Main Container */
.contact-container {
  display: flex;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

/* Contact Info Card */
.contact-info {
  flex: 1;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  animation: slideInLeft 1s ease-in-out forwards;
}
.contact-info h3 {
  font-size: 2rem;
  margin-bottom: 20px;
}
.contact-info p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  line-height: 1.6;
}
.info-details p {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  margin: 10px 0;
}
.info-details .icon {
  margin-right: 10px;
  color: #e63946;
}
.social-links {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}
.social-links a {
  background-color: #e63946;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}
.social-links a:hover {
  background-color: #c92c35;
}

/* Contact Form Card */
.contact-form-wrapper {
  flex: 2;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  animation: slideInRight 1s ease-in-out forwards;
}

/* Form Styles */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Submit Button */
.submit-btn {
  padding: 15px;
  background-color: #e63946;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.submit-btn:hover {
  background-color: #c92c35;
  transform: translateY(-3px);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }
  .contact-info,
  .contact-form-wrapper {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .contact-header h1 {
    font-size: 2.5rem;
  }
  .contact-header p {
    font-size: 1rem;
  }
  .contact-info h3 {
    font-size: 1.8rem;
  }
  .contact-info p,
  .info-details p {
    font-size: 1rem;
  }
  .submit-btn {
    font-size: 1rem;
    padding: 12px;
  }
}

/* Animations */
@keyframes fadeInPage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
