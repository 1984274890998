.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
}

.img .air-kitchenz-dark {
  height: 47px;
}

.navbar-toggle {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000;
}

.menu-icon {
  font-size: 1.5rem;
}

.group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.group div a {
  text-decoration: none;
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
  transition: color 0.3s ease;
}

.group div a:hover {
  color: #ff0000;
}

.profile-icon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.profile-link {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #000;
  transition: color 0.3s ease;
}

.profile-link:hover {
  color: #ff0000;
}

.feature-links {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e63946;
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  /* font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.feature-links:hover {
  background-color: #ff4d4d;
  color: #ffffff;
  transform: translateY(-3px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.feature-links:active {
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-profile-picture {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid black;
}

.navbar-profile-picture:hover {
  border: 2px solid #ff0000;
}

.greeting {
  font-size: 1rem;
  font-weight: bold;
}

.logout-button {
  background: none;
  border: none;
  color: #000;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.img {
  position: relative;
  display: inline-block;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .navbar-toggle {
    display: block;
  }

  .group {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    transition: all 0.3s ease;
  }

  .navbar-links.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .group div {
    padding: 10px 20px;
    text-align: center;
    transition: background-color 0.3s ease;
  }

  .group div:hover {
    background-color: #f9f9f9;
  }

  .group div a {
    text-align: center;
    display: block;
  }

  .img .air-kitchenz-dark {
    height: 40px;
  }

  .navbar-profile-picture {
    width: 35px;
    height: 35px;
  }

  .greeting {
    display: none;
  }
}
