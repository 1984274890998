.how-it-works-container {
  text-align: center;
  padding: 60px 20px;
  background-color: #ffffff;
  font-family: 'Nunito Sans', sans-serif;
}

.how-it-works-container h2 {
  font-size: 2.4rem;
  font-weight: 700;
  color: #2C3E50;
  margin-top: 0%;
  margin-bottom: 0%;
}

.steps {
  display: flex;
  flex-direction: column;
}

.step {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  max-width: 1000px;
  margin: 0 auto;
}

.step:nth-child(odd) .home-step-content {
  order: 2; 
}

.home-step-content {
  max-width: 50%;
  text-align: left;
}

.home-step-content h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #2C3E50;
}

.home-step-content p {
  font-size: 1.4rem;
  color: #555;
  line-height: 1.6;
}

.step-image-container {
  max-width: 40%;
}

.step-image {
  width: 75%;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .how-it-works-container h2 {
    font-size: 2rem;
  }


  .step {
    text-align: center;
  }

  .home-step-content, .step-image-container {
    max-width: 100%;
  }

  .home-step-content h3 {
    font-size: 1.6rem;
  }

  .home-step-content p {
    font-size: 0.9rem;
  }

}

@media (max-width: 480px) {
  .home-step-content h3 {
    font-size: 1.4rem;
  }

  .home-step-content p {
    font-size: 0.9rem;
  }

}