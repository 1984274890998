/* 
  1) Keyframes for fade + slide-up
     (from below, opacity 0 to full opacity and normal position)
*/
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
  2) The main container
*/
.restaurant-images {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Apply the fadeInUp animation */
  animation: fadeInUp 0.6s ease-out forwards;
}

.restaurant-images h2 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.restaurant-images label {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.restaurant-images input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;

  /* Subtle transition if you like a small hover or focus effect */
  transition: border-color 0.3s ease;
}

.restaurant-images input[type="file"]:hover,
.restaurant-images input[type="file"]:focus {
  border-color: #999;
}

/*
  3) Responsive Styles
*/
@media (max-width: 768px) {
  .restaurant-images {
    padding: 15px;
    margin: 1rem auto;
  }

  .restaurant-images h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .restaurant-images input[type="file"] {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .restaurant-images {
    padding: 10px;
    box-shadow: none;
    border-radius: 0%;
  }

  .restaurant-images h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .restaurant-images input[type="file"] {
    font-size: 13px;
  }
}
