.add-blog-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 30px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Arial", sans-serif;
}

.add-blog-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 2rem;
}

.add-blog-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blog-form-group {
  display: flex;
  flex-direction: column;
}

.blog-form-group label {
  font-size: 16px;
  color: #555;
  margin-bottom: 5px;
  font-weight: 600;
}

.blog-form-group input,
.blog-form-group .ql-container {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.blog-form-group input:focus,
.blog-form-group .ql-container:focus-within {
  border-color: #007bff;
  outline: none;
}

.blog-form-group .ql-container {
  min-height: 150px;
}

.form-actions {
  display: flex;
  justify-content: center;
}

.form-actions button {
  padding: 12px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-actions button:hover {
  background-color: #0056b3;
}

.form-actions button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
