/* 
  1) Keyframes for fade + slide up
     (from below, opacity 0 to full opacity and normal position)
*/
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
  2) Main Container
*/
.restaurant-details {
  padding: 20px;
  margin: 0 auto;
  max-width: 800px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 0.6s ease-out forwards;
  /* Apply the fadeInUp animation */
}

.restaurant-details h2 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.restaurant-details label {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.restaurant-details input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}

/*
  3) Buttons
*/
.restaurant-details button {
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  background-color: #393939;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  /* Subtle transform on hover */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.restaurant-details button:hover {
  background-color: #555;
  transform: scale(1.03);
}

/*
  4) Responsive Tweaks
*/
@media (max-width: 768px) {
  .restaurant-details {
    padding: 0%;
    box-shadow: none;
    border-radius: 0%;
  }

  .restaurant-details h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .restaurant-details input {
    font-size: 14px;
  }

  .restaurant-details button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .restaurant-details {
    padding: 10px;
  }

  .restaurant-details h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .restaurant-details input {
    font-size: 14px;
  }

  .restaurant-details button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
