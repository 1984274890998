.affiliate-page {
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Nunito Sans", sans-serif;
  padding-bottom: 20px;
}

.affiliate-header {
  width: 100%;
  background-color: #f9f9f9;
  padding: 20px 0;
}
.affiliate-header-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}
.affiliate-header h1 {
  font-size: 2.8rem;
  margin-bottom: 10px;
}
.affiliate-header p {
  font-size: 1.2rem;
  color: #333;
}

.affiliate-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 40px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 20px;
}
.campaign {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px 20px 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 45%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 450px;
}
.campaign-icon {
  margin-bottom: 10px;
}
.campaign h2 {
  color: black;
  font-size: 1.8rem;
  margin-bottom: 15px;
}
.campaign p {
  font-size: 1rem;
  margin-bottom: 15px;
  color: #555;
}
.campaign ul {
  list-style: disc;
  margin-left: 20px;
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
}
.campaign ul li {
  margin-bottom: 8px;
}

.affiliate-button {
  display: inline-block;
  background-color: #e63946;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: auto;
}
.affiliate-button:hover {
  background-color: #ff4d4d;
  transform: translateY(-3px);
  color: white;
}

.affiliate-info {
  width: 71%;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto 40px;
  padding: 20px;
}
.affiliate-info-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}
.affiliate-info h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: black;
}
.affiliate-info p {
  font-size: 1rem;
  margin-bottom: 15px;
  line-height: 1.6;
  color: #555;
}

@media (max-width: 768px) {
  .affiliate-header {
    padding: 0px;
  }
  .affiliate-header h1 {
    font-size: 1.7rem;
  }

  .affiliate-header p {
    font-size: 1.35rem;
  }

  .affiliate-content {
    flex-direction: column;
    gap: 30px;
    padding: 0 10px;
  }
  .campaign {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .affiliate-info {
    width: 95%;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  .affiliate-info h2 {
    font-size: 1.5rem;
  }
}
