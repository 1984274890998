.privacy-main-container {
  animation: fadeIn 0.5s ease-out forwards;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f7f7f7;
}

.privacy-container {
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
}

.privacy-container h2 {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.privacy-date {
  font-size: 1rem;
  color: #888;
  text-align: center;
  margin-bottom: 30px;
}

.privacy-content p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.8;
  margin-bottom: 20px;
}

.privacy-content h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
  margin-top: 30px;
  margin-bottom: 15px;
}

.privacy-content strong {
  color: #ff4b4b;
}

.privacy-content a {
  color: #ff4b4b;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.privacy-content a:hover {
  color: #e04b2e;
}

.privacy-content p + h3 {
  margin-top: 30px;
}

/* Responsive styling for larger screens */
@media (min-width: 1024px) {
  .privacy-container {
    padding: 50px 30px;
    max-width: 900px;
  }

  .privacy-container h2 {
    font-size: 3rem;
  }

  .privacy-content p {
    font-size: 1.2rem;
  }

  .privacy-content h3 {
    font-size: 1.5rem;
  }
}

/* Medium screens (landscape tablets, small desktops) */
@media (min-width: 768px) and (max-width: 1023px) {
  .privacy-container {
    padding: 40px 20px;
  }

  .privacy-container h2 {
    font-size: 2.25rem;
  }

  .privacy-content p {
    font-size: 1.1rem;
  }

  .privacy-content h3 {
    font-size: 1.3rem;
  }
}

/* Tablets and large smartphones */
@media (min-width: 481px) and (max-width: 767px) {
  .privacy-container {
    padding: 30px 15px;
  }

  .privacy-container h2 {
    font-size: 2rem;
  }

  .privacy-content p {
    font-size: 1rem;
  }

  .privacy-content h3 {
    font-size: 1.2rem;
  }
}

/* Small devices and smartphones */
@media (max-width: 480px) {
  .privacy-container {
    padding: 20px 10px;
  }

  .privacy-container h2 {
    font-size: 1.75rem;
  }

  .privacy-content p {
    font-size: 0.95rem;
  }

  .privacy-content h3 {
    font-size: 1.1rem;
  }

  .privacy-date {
    font-size: 0.9rem;
  }
}

/* Extra Small Devices */
@media (max-width: 360px) {
  .privacy-container {
    padding: 15px 5px;
  }

  .privacy-container h2 {
    font-size: 1.5rem;
  }

  .privacy-content p,
  .privacy-content h3 {
    font-size: 0.85rem;
  }

  .privacy-date {
    font-size: 0.85rem;
  }
}
