.map-component {
  width: 100%;
  height: 100%;
  min-height: 500px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.info-bubble {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  max-width: 200px;
  text-align: center;
}

.info-bubble-title {
  margin: 0;
  font-size: 1.1rem;
  color: #333;
  font-weight: bold;
}

.info-bubble-rate {
  margin: 5px 0 0;
  font-size: 1rem;
  color: #e63946;
  font-weight: bold;
}

@media (max-width: 768px) {
  .map-component {
    min-height: 500px;
  }
}

@media (max-width: 480px) {
  .map-component {
    min-height: 400px;
  }
}
