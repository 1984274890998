.my-restaurants-container {
  animation: fadeIn 0.5s ease-out forwards;
  padding: 20px;
  margin: 0 auto;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.my-restaurants-container h1 {
  text-align: left;
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}

.restaurants-header-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.restaurants-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.restaurant-item {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  gap: 20px;
  transition: box-shadow 0.3s, transform 0.3s;
}

.restaurant-item:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.my-restaurant-image img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.my-restaurant-details {
  flex: 1;
}

.my-restaurant-details h3 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 0 0 10px;
}

.my-restaurant-details p {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
  line-height: 1.6;
}

.my-restaurant-details .min-order {
  font-weight: bold;
  color: #007bff;
}

.restaurant-price-status {
  text-align: right;
}

.restaurant-price-status .delivery-fee {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
}

.restaurant-price-status .rating {
  font-size: 16px;
  font-weight: bold;
  color: #ffc107;
}

.restaurant-actions {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.restaurant-actions .edit-btn,
.restaurant-actions .archive-btn {
  text-decoration: none;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.restaurant-actions .edit-btn {
  background-color: #dc3545;
}

.restaurant-actions .archive-btn {
  background-color: #393939;
}

.restaurant-actions .archive-btn:hover,
.restaurant-actions .edit-btn:hover {
  opacity: 0.9;
}

.badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
}

.badge-active {
  background-color: #28a745;
}

.badge-inactive {
  background-color: #dc3545;
}

.bottom-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.loading-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #555;
  font-weight: bold;
  text-align: center;
}

.bottom-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.add-restaurant-btn {
  text-decoration: none;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #393939;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.add-restaurant-btn:hover {
  background-color: #000;
  transform: translateY(-2px);
  color: white;
}

@media (max-width: 768px) {
  .my-restaurants-container {
    padding: 15px;
    background-color: whitesmoke;
  }
  .restaurants-header-1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .restaurant-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .my-restaurant-image img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }

  .my-restaurant-details h3 {
    font-size: 18px;
    margin: 10px 0;
  }

  .my-restaurant-details p {
    font-size: 14px;
  }

  .restaurant-actions {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .restaurant-actions .edit-btn,
  .restaurant-actions .archive-btn {
    flex: 1;
    text-align: center;
    padding: 10px;
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .my-restaurants-container h1 {
    font-size: 22px;
  }

  .my-restaurant-details h3 {
    font-size: 16px;
  }

  .my-restaurant-details p {
    font-size: 12px;
  }
}
