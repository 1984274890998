.main-section-search {
  animation: fadeIn 0.3s ease-out forwards;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/herosection-bg.jpg") no-repeat center
    center;
  background-size: cover;
  height: 100vh;
  padding: 20px;
  text-align: center;
  overflow: hidden;
}

.main-section-search::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.main-section-search * {
  position: relative;
  z-index: 2;
}

.title {
  font-size: 3.5rem;
  color: #ffffff;
  margin: 0;
}

.subtitle {
  font-size: 1.2rem;
  color: #ffffff;
  margin: 10px 0 30px;
  max-width: 700px;
}

/* Adjusted width for a more compact search bar */
.home-filters {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 50px;
  max-width: 600px;
  width: 90%;
}

.input-group {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px 15px;
  border-radius: 30px;
  flex: 1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.input-icon {
  position: absolute;
  left: 15px;
  color: #888;
  font-size: 1rem;
}

.input-field {
  border: none;
  outline: none;
  font-size: 1rem;
  flex: 1;
  padding-left: 35px;
  background: none;
}

.search-button {
  background-color: #e63946;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #f72626;
}

.button-group {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.feature-button1 {
  display: flex;
  align-items: center;
  background-color: #e63946;
  color: #ffffff;
  font-size: 1.25rem;
  padding: 5px 10px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.feature-button1:hover {
  background-color: #f72626;
  color: #ffffff;
}

.button-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.badge-link {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
}

.badge-image {
  width: 270px;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.badge-image:hover {
  transform: scale(1.1);
}

/* Responsive styling */
@media (max-width: 768px) {
  .title {
    font-size: 2.4rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .input-field {
    font-size: 14px;
  }

  .feature-button1 {
    font-size: 0.8rem;
  }

  .badge-image {
    width: 180px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .home-filters {
    flex-direction: column;
  }

  .input-group,
  .search-button,
  .feature-button1 {
    width: 100%;
    margin: 5px 5px;
  }
  .badge-image {
    width: 150px;
  }
}
