.my-bookings-container {
  animation: fadeIn 0.5s ease-out forwards;
  height: 100%;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.my-bookings-container h1 {
  text-align: center;
  color: #333;
  font-size: 28px;
  margin-bottom: 30px;
  font-weight: bold;
  animation: fadeIn 1.2s ease-out forwards;
}

.bookings-list {
  list-style: none;
  padding: 0;
}

.booking-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  gap: 20px;
}

.booking-details {
  flex: 2;
  padding: 0 20px;
}

.booking-details h3 {
  font-size: 22px;
  color: #333;
  margin-bottom: 8px;
}

.booking-details p {
  font-size: 16px;
  margin: 4px 0;
}

.booking-price {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  color: #333;
}

.booking-price p {
  margin: 4px 0;
}

.invoice-button {
  margin-top: 10px;
  padding: 12px 20px;
  background-color: #393939;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.invoice-button:hover {
  background-color: black;
  transform: scale(1.01);
}

.status {
  font-weight: bold;
}

.status-pending {
  color: #ffc107;
}

.status-confirmed {
  color: #28a745;
}

.status-cancelled {
  color: #dc3545;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #555;
  font-weight: bold;
  text-align: center;
}

/* Modal Styles */
.booking-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 20px;
  z-index: 1000; 
  animation: fadeIn 0.3s ease-in-out;
}
.booking-modal {
  background: #fff;
  padding: 30px;
  max-width: 800px;
  width: 100%;
  max-height: 90vh;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.5s ease-in-out;
}

.booking-modal-content {
  overflow-y: auto;
  padding-right: 10px; 
}

.booking-modal-header {
  text-align: center;
  margin-bottom: 20px;
}

.booking-modal-logo {
  width: 150px;
  height: auto;
  margin-bottom: 10px;
}

.booking-modal h2 {
  margin-bottom: 10px;
  color: #333;
}

.booking-modal-company-info {
  text-align: center;
  margin-bottom: 20px;
}

.booking-modal-company-info h3 {
  margin-bottom: 5px;
  color: #333;
}

.booking-modal-company-info p {
  margin: 2px 0;
  color: #555;
}

.booking-modal-customer-info {
  width: 100%;
  margin-bottom: 20px;
}

.booking-modal-customer-info h4 {
  margin-bottom: 10px;
  color: #333;
}

.booking-modal-customer-info p {
  font-size: 16px;
  color: #555;
  margin: 4px 0;
}

.booking-modal-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.booking-modal-table th,
.booking-modal-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.booking-modal-table th {
  background-color: #f2f2f2;
  color: #333;
}

.booking-modal-summary {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.booking-modal-summary-item {
  margin-left: 20px;
  font-size: 16px;
  color: #555;
}

.booking-modal-instructions {
  width: 100%;
  margin-bottom: 20px;
}

.booking-modal-instructions h4 {
  margin-bottom: 10px;
  color: #333;
}

.booking-modal-instructions p {
  font-size: 14px;
  color: #555;
}

.booking-modal-instructions a.booking-modal-link {
  color: #007bff;
  text-decoration: none;
}

.booking-modal-instructions a.booking-modal-link:hover {
  text-decoration: underline;
}

.booking-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.booking-modal-download {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.booking-modal-download:hover {
  background-color: #218838;
}

.booking-modal-download-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.booking-modal-close {
  padding: 10px 20px;
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.booking-modal-close:hover {
  background-color: #5a6268;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .my-bookings-container {
    padding: 0%;
  }

  .booking-item {
    flex-direction: column;
  }

  .booking-price {
    margin-top: 20px;
  }

  .booking-modal-summary {
    flex-direction: column;
    align-items: flex-start;
  }

  .booking-modal-summary-item {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .booking-modal-footer {
    flex-direction: column;
    align-items: stretch;
  }

  .booking-modal-download,
  .booking-modal-close {
    width: 100%;
    text-align: center;
  }

  .booking-modal {
    max-width: 100%;
    max-height: 95vh;
  }
}
