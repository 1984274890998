.about-us-container {
  animation: fadeIn 0.5s ease-out forwards;
  font-family: "Nunito Sans", sans-serif;
  background-color: #fff;
}

.about-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 500px;
  margin: 20px auto;
  max-width: 90%;
}

.aboutus-image-container {
  width: 45%;
  height: 80%;
  overflow: hidden;
  border-radius: 3px;
}

.aboutus-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  width: 50%;
  padding-left: 20px;
}

.text-container h2 {
  color: red;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.text-container p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

/* Story Section Styling */
.story-section {
  margin: 20px auto;
  max-width: 90%;
  padding: 20px;
  background-color: #fbfbfb;
  border-radius: 3px;
}

.story-content h2 {
  color: red;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.story-content p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
  text-align: justify;
}

.story-content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px;
}

.story-content ul li {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 5px;
}

/* Mobile adjustments for Story Section */
@media (max-width: 768px) {
  .story-section {
    margin: 20px;
    padding: 15px;
  }

  .story-content h2 {
    font-size: 1.6rem;
  }

  .story-content p,
  .story-content ul li {
    font-size: 1rem;
  }
}

.team-header h2 {
  color: red;
  font-size: 2rem;
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
}

.team-header p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  max-width: 90%;
  margin: 20px auto;
  text-align: justify;
}

/* Team Section Grid */
.team-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 0 20px;
  margin: 20px auto;
  max-width: 90%;
  justify-items: center;
  box-sizing: border-box;
}

/* Team Member Styling */
.team-member {
  background-color: #f9f9f9;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 300px;
  box-sizing: border-box;
}

.team-member:hover {
  transform: translateY(-5px);
}

.team-member img {
  width: 100%;
  height: auto;
  border-radius: 3px;
  border-bottom: 3px solid #ff5733;
}

.team-member h4 {
  font-size: 1.2rem;
  margin: 10px 0 5px;
  color: #222;
  font-weight: 900;
}

.team-member p {
  font-size: 1rem;
  color: #777;
  font-weight: 600;
  text-align: justify;
}

/* Mobile Adjustment for Team Section */
@media (max-width: 768px) {
  .about-header {
    flex-direction: column;
    height: auto;
  }

  .aboutus-image-container {
    width: 100%;
    height: 250px;
  }

  .text-container {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }

  .text-container h2 {
    font-size: 1.8rem;
  }

  .text-container p {
    max-width: 100%;
  }

  .team-section {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 0 10px;
    gap: 15px;
  }

  .team-member {
    padding: 10px;
    max-width: 100%;
    width: 100%;
  }

  .team-member h4 {
    font-size: 1.1rem;
  }

  .team-member p {
    font-size: 1rem;
  }

  .team-header p {
    margin: 0 15px;
  }
}
